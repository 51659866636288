.slick-prev::before {
    color: black !important;
    left: 3% !important;
    z-index: 1;
    display: none;
}

.slick-next::before {
    color: black !important;
    z-index: 1;
}