.photoFrame img {
    width: 256px;
    height: 256px;
}

.photoFrame {
    background-color: #FEFEFE;
    width: 300px;
    height: 300px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px #AAA;
    -moz-box-shadow: 0px 0px 5px 0px #AAA;
    -webkit-box-shadow: 0px 0px 5px 0px #AAA;
    transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
}

.photoFrame:hover {
    box-shadow: 1px 1px 1px 0px #999,
        2px 2px 1px 0px #888,
        3px 3px 1px 0px #777,
        4px 4px 1px 0px #666,
        5px 5px 1px 0px #555,
        6px 6px 1px 0px #444,
        7px 7px 1px 0px #333,
        8px 8px 3px 0px #303030;
    -moz-box-shadow: 1px 1px 1px 0px #999,
        2px 2px 1px 0px #888,
        3px 3px 1px 0px #777,
        4px 4px 1px 0px #666,
        5px 5px 1px 0px #555,
        6px 6px 1px 0px #444,
        7px 7px 1px 0px #333,
        8px 8px 3px 0px #303030;
    -webkit-box-shadow: 1px 1px 1px 0px #999,
        2px 2px 1px 0px #888,
        3px 3px 1px 0px #777,
        4px 4px 1px 0px #666,
        5px 5px 1px 0px #555,
        6px 6px 1px 0px #444,
        7px 7px 1px 0px #333,
        8px 8px 3px 0px #303030;
    transform: translate(-8px, -8px);
    -ms-transform: translate(-8px, -8px);
    -moz-transform: translate(-8px, -8px);
    -webkit-transform: translate(-8px, -8px);
    -o-transform: translate(-8px, -8px);

    transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
}